import React from 'react'
import { Helmet } from 'react-helmet'

// Components
import DefaultLayout from 'layouts/DefaultLayout'
import Intro from 'components/Projects/ProjectSingle/IntroSection'
import Content from 'components/Projects/ProjectSingle/Content'
import ProjectLinks from 'components/Projects/ProjectSingle/ProjectLinks'
import { projectData } from 'components/Projects/ProjectData'

const Project2 = () => {
  const project = projectData.find((project) => project.name === 'Project 1')

  return (
    <DefaultLayout>
      <Helmet title="Project: Project 2" />

      <Intro project={project} />

      <Content>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>

        <ProjectLinks liveSite="http://www.example.com/" />
      </Content>
    </DefaultLayout>
  )
}

export default Project2
